<template>
  <v-app>
    <v-app-bar
        app
        class="pa-3"
        color="white"
        flat
        style="z-index: 200"
        v-if="!smallscreen"
      >
      <v-toolbar-title >
        <v-btn v-if="!smallscreen" class="ma-4 grey--text text--darken-1 text-h4 font-weight-normal" 
        depressed plain
        text @click="handleSelect('Home')">Lisa Ianigro </v-btn>
        <v-btn v-if="smallscreen" class="pa-0 grey--text text--darken-1 body font-weight-normal" 
        depressed plain
        text @click="handleSelect('Home')">Lisa Ianigro</v-btn></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="!smallscreen">
        <v-btn
            text
            depressed plain
            class="text-capitalize black--text font-weight-light pa-2"
            @click="handleSelect('newwork')">
        New Work
      </v-btn>
      <v-btn
            text
            depressed plain
            class="text-capitalize black--text font-weight-light pa-2"
            @click="handleSelect('paintings')">
        Paintings
      </v-btn>
      <v-btn
            text
            depressed plain 
            class="text-capitalize black--text font-weight-light pa-2"
            @click="handleSelect('about')">
        About
      </v-btn>
      <v-btn
            text
            depressed plain
            class="text-capitalize black--text font-weight-light pa-2"
            @click="handleSelect('Contact')">
        Contact
      </v-btn>
      <v-btn
        text
        small
        icon
        class="text-capitalize grey--text font-weight-light pa-0"
        href="https://www.instagram.com/lisaianigro_art/">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
  <v-app-bar 
        app
        class="pa-0"
        color="white"
        flat
        v-if="smallscreen">
        <v-btn v-if="smallscreen" class="pa-0 mr-4 grey--text text--darken-1 body font-weight-normal" 
        depressed plain
        text @click="handleSelect('Home')">Lisa Ianigro</v-btn><p></p>
      <v-spacer/>
      <v-toolbar-items>
        <v-btn
            text
            depressed plain
            class="caption text-capitalize black--text font-weight-light pa-0"
            @click="handleSelect('newwork')">
        New Work
      </v-btn>

      <v-btn
            text
            depressed plain
            class="caption text-capitalize black--text font-weight-light pa-0"
            @click="handleSelect('paintings')">
        Paintings
      </v-btn>
      <v-btn
            text
            depressed plain 
            class="caption text-capitalize black--text font-weight-light pa-0"
            @click="handleSelect('about')">
        About
      </v-btn>
      <v-btn
            text
            depressed plain
            class="caption text-capitalize black--text font-weight-light pa-0"
            @click="handleSelect('Contact')">
        Contact
      </v-btn>

      </v-toolbar-items>

</v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
</v-app>
</template>

<script>

export default {
  name: 'App',
  metaInfo: {
    title: "Lisa Ianigro Art",
    desription:
      "Lisa Ianigro Art Painter and Artist",
      titleTemplate: null,
  },
  data: () => ({
    //
  }),

  computed: {
    smallscreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
       default:
          return false;
      }
    },
  },
  mounted: function() {
    this.handleSelect('Home');
  },
  methods: {
    handleSelect(key) {
        this.$router
          .push({
            name: key,
          })
          .catch((e) => {
            if (e.name != "NavigationDuplicated") {
              // But print any other errors to the console
              console.error();
            }
          });
      },
  }
};
</script>
